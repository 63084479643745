$(function () {
    $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 15,
        center: true,
        responsiveClass: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplaySpeed: 6000,
        //fluidSpeed: true,
        smartSpeed: 6000,

        touchDrag: false,
        mouseDrag: false,
        pullDrag: false,
        freeDrag: false,

        responsive: {
            0: {
                items: 1,
            },
            300: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4
            }
        }
    });
});